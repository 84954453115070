<script>
/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkCalendar from "@components/input/AkCalendar";
import AkCheckbox from "@components/input/AkCheckbox";
import AkDropdown from "@components/input/AkDropdown";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import loaderMixin from "@mixins/loaderMixin";
import dateFormatter from "@mixins/dateFormatter";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import agerService from "@services/agerService";
import uerService from "@services/uerService";
import ceiService from "@services/ceiService";
import cei2Service from "@services/cei2Service";
import forecastSheetService from "@services/forecastSheetService";
import linkSheetService from "@services/linkSheetService";
import mobileMixin from "@mixins/mobileMixin";

export default {
  components: {AkFormView, AkCalendar, AkCheckbox, AkDropdown},
  mixins: [randomRef, loaderMixin, dateFormatter, roleMixin, mobileMixin],
  metaInfo() {
    return {
      title: "modernized_daily_summary.list",
    }
  },
  data() {
    return {
      year: 0,
      yearList: [],
      agerList: [],
      uerList: [],
      ceiList: [],
      cei2List: [],
      ceiListToDisplay: [],
      linkSheetList: [],
      forecastSheetList: [],
      linkSheetListToDisplay: [],
      forecastSheetListToDisplay: [],
      table: "",
      tableConstHeader: "",
      date: new Date(),
      minDate: new Date(),
      maxDate: new Date(),
      totals: {},
      displayVirtualCei: false,
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
  
    let p1 = linkSheetService.findAllYears().then(data => {
      if (!this.canOnlyViewArchivedMD()) {
        this.yearList.push({value: 0, label: this.$t('current_year')});
      }
      data.forEach(year => this.yearList.push({value: year, label: year + ' - ' + (year + 1)}));
    });
  
    Promise.all([p1]).then(() => {
      if (this.canOnlyViewArchivedMD()) {
        this.year = this.yearList[0].value;
        this.initData();
        this.loading = false;
        this.getRef().hideLoader();
      } else {
        let p2, p3, p4, p5, p6, p7;
        p2 = agerService.findAllCurrent();
        p2.then(data => this.agerList = data);
        p3 = uerService.findAllCurrent();
        p3.then(data => this.uerList = data);
        p4 = ceiService.findAllCurrent();
        p4.then(data => this.ceiList = data);
        p5 = cei2Service.findAllCurrent();
        p5.then(data => this.cei2List = data);
        p6 = linkSheetService.findAllCurrentValidatedWithRows();
        p6.then(data => this.linkSheetList = data);
        p7 = forecastSheetService.findAllCurrentValidatedWithRows();
        p7.then(data => this.forecastSheetList = data);
      
        Promise.all([p2, p3, p4, p5, p6, p7]).then(() => this.afterPromise());
      }
    });
  },
  methods: {
    initData() {
      let linkSheetDateList = [];
      let forecastSheetDateList = [];
      
      this.linkSheetListToDisplay = this.linkSheetList.filter(l => this.sameDay(l.linkSheet.date, this.date));
      this.forecastSheetListToDisplay = this.forecastSheetList.filter(f => this.sameDay(f.forecastSheet.date, this.date));
      this.linkSheetList.forEach(l => linkSheetDateList.push(l.linkSheet.date));
      this.forecastSheetList.forEach(f => forecastSheetDateList.push(f.forecastSheet.date));
      this.minDate = this.findMinDateByDay(linkSheetDateList.concat(forecastSheetDateList));
      this.maxDate = this.findMaxDateByDay(linkSheetDateList.concat(forecastSheetDateList));
      if (this.date > this.maxDate) this.date = this.maxDate;
      if (this.date < this.minDate) this.date = this.minDate;
      this.refreshDisplay();
    },
    afterPromise() {
      this.loading = false;
      this.getRef().hideLoader();
      this.initData();
    },
    reload(year) {
      this.loading = true;
      this.getRef().showTotalLoader();
      let p1, p2, p3, p4, p5, p6;
      if (year !== 0) {
        p1 = agerService.findAllByYear(year);
        p1.then(data => this.agerList = data);
        p2 = uerService.findAllByYear(year);
        p2.then(data => this.uerList = data);
        p3 = ceiService.findAllByYear(year);
        p3.then(data => this.ceiList = data);
        p4 = cei2Service.findAllByYear(year);
        p4.then(data => this.cei2List = data);
        p5 = linkSheetService.findAllByYearValidatedWithRows(year);
        p5.then(data => this.linkSheetList = data);
        p6 = forecastSheetService.findAllByYearValidatedWithRows(year);
        p6.then(data => this.forecastSheetList = data);
      } else {
        p1 = agerService.findAllCurrent();
        p1.then(data => this.agerList = data);
        p2 = uerService.findAllCurrent();
        p2.then(data => this.uerList = data);
        p3 = ceiService.findAllCurrent();
        p3.then(data => this.ceiList = data);
        p4 = cei2Service.findAllCurrent();
        p4.then(data => this.cei2List = data);
        p5 = linkSheetService.findAllCurrentValidatedWithRows();
        p5.then(data => this.linkSheetList = data);
        p6 = forecastSheetService.findAllCurrentValidatedWithRows();
        p6.then(data => this.forecastSheetList = data);
      }
      Promise.all([p1, p2, p3, p4, p5, p6]).then(() => this.afterPromise());
    },
    ceiListForAger(agerId) {
      return this.ceiListToDisplay.filter(c => c.agerId === agerId);
    },
    uerListForAger(agerId) {
      return this.uerList.filter(u => u.agerId === agerId);
    },
    ceiListForUer(uerId) {
      return this.ceiListToDisplay.filter(c => c.uerId === uerId);
    },
    linkSheetListForCei(ceiId, virtual, useSnapshotId) {
      if (virtual) {
        let cei2 = this.ceiListToDisplay.filter(c => c.id === ceiId && c.virtual)[0];
        let ceiIds = cei2.ceiList.map(c => c.id);
        return this.linkSheetListToDisplay.filter(s =>{ 
          if(useSnapshotId) {
            return ceiIds.includes(s.linkSheet.snapshotCeiId)
          }else {
            return ceiIds.includes(s.linkSheet.ceiId)
          }
        });
      } else {
        return this.linkSheetListToDisplay.filter(s => {
          if(useSnapshotId) {
            return s.linkSheet.snapshotCeiId === ceiId
          } else {
            return s.linkSheet.ceiId === ceiId
          }
        });
      }
    },
    forecastSheetListForCei(ceiId, virtual, useSnapshotId) {
      if (virtual) {
        let cei2 = this.ceiListToDisplay.filter(c => c.id === ceiId && c.virtual)[0];
        let ceiIds = cei2.ceiList.map(c => c.id);
        return this.forecastSheetListToDisplay.filter(s => {
          if(useSnapshotId) return ceiIds.includes(s.forecastSheet.snapshotCeiId);
          else return  ceiIds.includes(s.forecastSheet.ceiId);
        });
      } else {
        
        return this.forecastSheetListToDisplay.filter(s => {
          if(useSnapshotId) return s.forecastSheet.snapshotCeiId === ceiId;
          else return s.forecastSheet.ceiId === ceiId;
          
        });
      }
    },
    computeValuesPerCei() {
      this.totals.nbForecastSheet = 0;
      this.totals.nbLinkSheet = 0;
      this.totals.nbPatrol = 0;
      this.totals.nbPrecurative = 0;
      this.totals.nbCurative = 0;
      this.totals.nbSingularPoints = 0;
  
      for (const cei of this.ceiListToDisplay) {
        // forecast sheet nb
        cei.nbForecastSheet = this.forecastSheetListForCei(cei.id, cei.virtual,this.displayArchiveData).length;
        this.totals.nbForecastSheet += cei.nbForecastSheet;
    
        // link sheet nb
        let linkSheets = this.linkSheetListForCei(cei.id, cei.virtual,this.displayArchiveData);
        cei.nbLinkSheet = linkSheets.length;
        this.totals.nbLinkSheet += linkSheets.length;
    
        cei.nbPatrol = 0;
        cei.nbPrecurative = 0;
        cei.nbCurative = 0;
        cei.nbSingularPoints = 0;
        for (const linkSheet of linkSheets) {
          for (const element of linkSheet.linkRows) {
            if (element.patrol) cei.nbPatrol++;
            if (element.precurative) cei.nbPrecurative++;
            if (element.curative) cei.nbCurative++;
            if (element.singularPoint) cei.nbSingularPoints++;
          }
        }
        this.totals.nbPatrol += cei.nbPatrol;
        this.totals.nbPrecurative += cei.nbPrecurative;
        this.totals.nbCurative += cei.nbCurative;
        this.totals.nbSingularPoints += cei.nbSingularPoints;
      }
    },
    mergeCeiLists() {
      for (const element of this.cei2List) {
        let cei2 = element;
        cei2.ceiList = this.ceiListToDisplay.filter(cei => cei.cei2Id === cei2.id);
        cei2.virtual = true;
        this.ceiListToDisplay = this.ceiListToDisplay.filter(cei => cei.cei2Id !== cei2.id);
      }
      for (const element of this.cei2List) {
        this.ceiListToDisplay.push(element);
      }
      this.agerList.sort((a, b) => {
        return a.id - b.id;
      });
      this.uerList.sort((a, b) => {
        if (a.agerId !== b.agerId) {
          return a.agerId - b.agerId;
        } else {
          return a.id - b.id;
        }
      });
      this.ceiListToDisplay.sort((a, b) => {
        if (a.agerId !== b.agerId) {
          return a.agerId - b.agerId;
        } else if (a.uerId !== b.uerId) {
          return a.uerId - b.uerId;
        } else {
          return a.id - b.id;
        }
      });
    },
    buildHeader() {
      let header = "<thead><tr><td><bold>" + this.$t('ager_label') + "</bold></td>";
      for (const element of this.agerList) {
        let ager = element;
        header += "<td colspan=" + this.ceiListForAger(ager.id).length + "><bold>" + ager.label + "</bold></td>"
      }
      header += "<td rowspan=\"3\"><bold>" + this.$t('app_name') + "</bold></td></tr><tr><td><bold>" + this.$t('uer_label') + "</bold></td>"
      for (const ager of this.agerList) {
        for (const uer of this.uerListForAger(ager.id)) {
          header += "<td colspan=" + this.ceiListForUer(uer.id).length + "><bold>" + uer.label + "</bold></td>"
        }
      }
      header += "</tr><tr><td><bold>" + this.$t('cei_label') + "</bold></td>"
      for (const ager of this.agerList) {
        for (const uer of this.uerListForAger(ager.id)) {
          for (const cei of this.ceiListForUer(uer.id)) {
            header += "<td>" + cei.label + "</td>"
          }
        }
      }
      header += "</tr></thead>";
      this.tableConstHeader = header;
    },
    buildSummary() {
      // Header
      let table = "<table class='summary-table'>";
      table += this.tableConstHeader;
  
      // Forecast Sheets
      table += "<tbody><tr><td><bold>" + this.$t('forecast_sheet.list') + "</bold></td>"
      for (const element of this.ceiListToDisplay) {
        table += "<td>" + element.nbForecastSheet + "</td>"
      }
      table += "<td><bold>" + this.totals.nbForecastSheet + "</bold></td></tr>"
  
      // Link Sheets
      table += "<tr><td><bold>" + this.$t('link_sheet.list') + "</bold></td>"
      for (const element of this.ceiListToDisplay) {
        table += "<td>" + element.nbLinkSheet + "</td>"
      }
      table += "<td><bold>" + this.totals.nbLinkSheet + "</bold></td></tr>"
  
      // Patrol
      table += "<tr><td><bold>" + this.$t('link_sheet.patrol') + "</bold></td>"
      for (const element of this.ceiListToDisplay) {
        table += "<td>" + element.nbPatrol + "</td>"
      }
      table += "<td><bold>" + this.totals.nbPatrol + "</bold></td></tr>"
  
      // Precurative
      table += "<tr><td><bold>" + this.$t('link_sheet.precurative') + "</bold></td>"
      for (const element of this.ceiListToDisplay) {
        table += "<td>" + element.nbPrecurative + "</td>"
      }
      table += "<td><bold>" + this.totals.nbPrecurative + "</bold></td></tr>"
  
      // Curative
      table += "<tr><td><bold>" + this.$t('link_sheet.curative') + "</bold></td>"
      for (const element of this.ceiListToDisplay) {
        table += "<td>" + element.nbCurative + "</td>"
      }
      table += "<td><bold>" + this.totals.nbCurative + "</bold></td></tr>"
  
      // Singular points
      table += "<tr><td><bold>" + this.$t('link_sheet.singular_points') + "</bold></td>"
      for (const element of this.ceiListToDisplay) {
        table += "<td>" + element.nbSingularPoints + "</td>"
      }
      table += "<td><bold>" + this.totals.nbSingularPoints + "</bold></td></tr>"
  
      table += "</tbody></table>";
      this.table = table;
    },
    refreshDisplay() {
      this.ceiListToDisplay = this.ceiList;
      if (this.displayVirtualCei) {
        this.mergeCeiLists();
      }
      this.buildHeader();
      this.computeValuesPerCei();
      this.buildSummary();
    },
    changeDate() {
      this.getRef().showTotalLoader();
  
      this.linkSheetListToDisplay = this.linkSheetList.filter(l => this.sameDay(l.linkSheet.date, this.date));
      this.forecastSheetListToDisplay = this.forecastSheetList.filter(f => this.sameDay(f.forecastSheet.date, this.date));
  
      this.refreshDisplay();
      this.getRef().hideLoader();
    },
    previousDay() {
      if (this.date.getTime() - (3600 * 1000 * 24) >= this.minDate.getTime())
        this.date = new Date(this.date.getTime() - (3600 * 1000 * 24));
    },
    nextDay() {
      if (this.date.getTime() + (3600 * 1000 * 24) <= this.maxDate.getTime())
        this.date = new Date(this.date.getTime() + (3600 * 1000 * 24));
    }
  },
  computed: {
    title() {
      return this.$t('modernized_daily_summary.title', [this.prettyFormatDate(this.date)]);
    },
    displayArchiveData(){
      return this.year !== 0;
    },
    summaryData(){
      let summaryStructure = {}
      summaryStructure.ager = []
      for (const ager of this.agerList) {
        let tmpAger = {id:ager.id, label:ager.label, uer : [], nbCei : 0};
        let nbCei = 0
        for (const uer of this.uerListForAger(ager.id)) {
          let tmpUer = {id:uer.id,label:uer.label, cei : []}
          for (const cei of this.ceiListForUer(uer.id)) {
            tmpUer.cei.push({id:cei.id, label:cei.label})
            nbCei++;
          }
          tmpAger.uer.push(tmpUer);
        }
        tmpAger.nbCei = nbCei;
        summaryStructure.ager.push(tmpAger);
      }


      let summaryData = {};
      summaryData.nbLinkSheet = {total:0}
      summaryData.nbForecastSheet = {total:0}
      summaryData.nbPatrol = {total:0}
      summaryData.nbPrecurative = {total:0}
      summaryData.nbCurative = {total:0}
      summaryData.nbSingularPoints = {total:0}
      this.ceiListToDisplay.forEach(cei => {

        summaryData.nbLinkSheet['total'] +=cei.nbLinkSheet;
        summaryData.nbLinkSheet[cei.id] =  cei.nbLinkSheet;

        summaryData.nbForecastSheet['total'] +=cei.nbForecastSheet;
        summaryData.nbForecastSheet[cei.id] =  cei.nbForecastSheet;

        summaryData.nbPatrol['total'] +=cei.nbPatrol;
        summaryData.nbPatrol[cei.id] =  cei.nbPatrol;

        summaryData.nbPrecurative['total'] +=cei.nbPrecurative;
        summaryData.nbPrecurative[cei.id] =  cei.nbPrecurative;

        summaryData.nbCurative['total'] +=cei.nbCurative;
        summaryData.nbCurative[cei.id] =  cei.nbCurative;

        summaryData.nbSingularPoints['total'] +=cei.nbSingularPoints;
        summaryData.nbSingularPoints[cei.id] =  cei.nbSingularPoints;
      })
      return {summaryStructure, summaryData};
    }
  },
  watch: {
    date() {
      this.changeDate();
    },
    displayVirtualCei() {
      this.refreshDisplay();
    },
    year(newType) {
      this.reload(newType);
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="title" :displayGoBack=false>
    <template v-slot:form>
      <div class="form-row">
        <AkDropdown :label="$t('year')"
                    v-model="year"
                    :options=this.yearList
                    class-name="col-md-2"/>
        <AkCalendar v-model=date :label="$t('date')"
                    :maxDate=maxDate :minDate=minDate class-name="col-md-2"/>
        <div class="form-group">
          <label>&nbsp;</label>
          <span style="display: flex;">
            <span class="btn btn-xs btn-inverse-primary flex-center" style="margin: 0 10px 0 5px; height: 40px;" @click="previousDay()">
              <i class="fe fe-arrow-left"/>
            </span>
            <span class="btn btn-xs btn-inverse-primary flex-center" style="height: 40px;" @click="nextDay()">
              <i class="fe fe-arrow-right"/>
            </span>
          </span>
        </div>
      </div>
      <div class="form-row">
        <AkCheckbox v-model="displayVirtualCei" :label="$t('modernized_daily_summary.virtual_cei')" class-name="col-md-2"/>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <!-- <div class="table-responsive forecast-table">
                <div v-html="table"/>
              </div> -->
              <div class="table-wrapper forecast-table">
                <table id="table" class="summary-table">
                  <thead id="table_head">
                    <tr>
                      <td :class="!isMobile ? 'sticky-col' : ''"><bold>{{$t('ager_label')}}</bold></td>
                      <template v-for="ager in summaryData.summaryStructure?.ager" :key="ager.id">
                        <td :class="!isMobile ? 'sticky-col' : ''" :style="!isMobile ? 'left: 82.7px;' : ''" :colspan="ager.nbCei">{{ ager.label }}</td>
                      </template>
                      <td rowspan="3"><bold>{{$t('app_name')}}</bold></td>
                    </tr>
                    <tr>
                      <td :class="!isMobile ? 'sticky-col' : ''"><bold>{{$t('uer_label')}}</bold></td>
                      <template v-for="ager in summaryData.summaryStructure?.ager" :key="ager.id">
                        <template v-for="uer in ager?.uer" :key="uer.id">
                        <td :class="!isMobile ? 'sticky-col' : ''" :style="!isMobile ? 'left: 82.7px;' : ''" :colspan="uer.cei.length">{{ uer.label }}</td>
                        </template>
                      </template>
                    
                    </tr>
                    <tr>
                      <td :class="!isMobile ? 'sticky-col' : ''"><bold>{{$t('cei_label')}}</bold></td>
                      <template v-for="ager in summaryData.summaryStructure?.ager" :key="ager.id">
                        <template v-for="uer in ager?.uer" :key="uer.id">
                          <template v-for="cei in uer?.cei" :key="cei.id">
                            <td :class="!isMobile ? 'sticky-col' : ''" :style="!isMobile ? 'left: 82.7px;' : ''">{{ cei.label }}</td>
                          </template>
                        </template>
                      </template>
                    
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td :class="!isMobile ? 'sticky-col' : ''"><bold>{{ $t('forecast_sheet.list') }}</bold></td>
                        <template v-for="ager in summaryData.summaryStructure?.ager" :key="ager.id">
                        <template v-for="uer in ager?.uer" :key="uer.id">
                          <template v-for="cei in uer?.cei" :key="cei.id">
                            <td :class="!isMobile ? 'sticky-col' : ''" :style="!isMobile ? 'left: 82.7px;' : ''">{{ summaryData.summaryData.nbForecastSheet[cei.id] }}</td>
                          </template>
                        </template>
                      </template>
                      <td><bold>{{ summaryData.summaryData.nbForecastSheet.total }}</bold></td>
                      </tr>
                      
                      <tr>
                        <td :class="!isMobile ? 'sticky-col' : ''"><bold>{{ $t('link_sheet.list') }}</bold></td>
                        <template v-for="ager in summaryData.summaryStructure?.ager" :key="ager.id">
                        <template v-for="uer in ager?.uer" :key="uer.id">
                          <template v-for="cei in uer?.cei" :key="cei.id">
                            <td :class="!isMobile ? 'sticky-col' : ''" :style="!isMobile ? 'left: 82.7px;' : ''">{{ summaryData.summaryData.nbLinkSheet[cei.id] }}</td>
                          </template>
                        </template>
                      </template>
                      <td><bold>{{ summaryData.summaryData.nbLinkSheet.total }}</bold></td>
                      
                      </tr>

                      <tr>
                        <td :class="!isMobile ? 'sticky-col' : ''"><bold>{{ $t('link_sheet.patrol') }}</bold></td>
                        <template v-for="ager in summaryData.summaryStructure?.ager" :key="ager.id">
                          <template v-for="uer in ager?.uer" :key="uer.id">
                            <template v-for="cei in uer?.cei" :key="cei.id">
                              <td :class="!isMobile ? 'sticky-col' : ''" :style="!isMobile ? 'left: 82.7px;' : ''">{{ summaryData.summaryData.nbPatrol[cei.id] }}</td>
                            </template>
                          </template>
                        </template>
                        <td><bold>{{ summaryData.summaryData.nbPatrol.total }}</bold></td>
                      </tr>

                      <tr>
                        <td :class="!isMobile ? 'sticky-col' : ''"><bold>{{ $t('link_sheet.precurative') }}</bold></td>
                        <template v-for="ager in summaryData.summaryStructure?.ager" :key="ager.id">
                          <template v-for="uer in ager?.uer" :key="uer.id">
                            <template v-for="cei in uer?.cei" :key="cei.id">
                              <td :class="!isMobile ? 'sticky-col' : ''" :style="!isMobile ? 'left: 82.7px;' : ''">{{ summaryData.summaryData.nbPrecurative[cei.id] }}</td>
                            </template>
                          </template>
                        </template>
                        <td><bold>{{ summaryData.summaryData.nbPrecurative.total }}</bold></td>
                      </tr>

                      <tr>
                        <td :class="!isMobile ? 'sticky-col' : ''"><bold>{{ $t('link_sheet.curative') }}</bold></td>
                        <template v-for="ager in summaryData.summaryStructure?.ager" :key="ager.id">
                          <template v-for="uer in ager?.uer" :key="uer.id">
                            <template v-for="cei in uer?.cei" :key="cei.id">
                              <td :class="!isMobile ? 'sticky-col' : ''" :style="!isMobile ? 'left: 82.7px;' : ''">{{ summaryData.summaryData.nbCurative[cei.id] }}</td>
                            </template>
                          </template>
                        </template>
                        <td><bold>{{ summaryData.summaryData.nbCurative.total }}</bold></td>
                      </tr>

                      <tr>
                        <td :class="!isMobile ? 'sticky-col' : ''"><bold>{{ $t('link_sheet.singular_points') }}</bold></td>
                        <template v-for="ager in summaryData.summaryStructure?.ager" :key="ager.id">
                          <template v-for="uer in ager?.uer" :key="uer.id">
                            <template v-for="cei in uer?.cei" :key="cei.id">
                              <td :class="!isMobile ? 'sticky-col' : ''" :style="!isMobile ? 'left: 82.7px;' : ''">{{ summaryData.summaryData.nbSingularPoints[cei.id] }}</td>
                            </template>
                          </template>
                        </template>
                        <td><bold>{{ summaryData.summaryData.nbSingularPoints.total }}</bold></td>
                      </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AkFormView>
</template>
<style scoped>
#table > tbody > tr > td {
  top: 239px;
  background: #fff;
  position: sticky;
  width: min-content;
}

#table_head {
  height: 239px;
}
</style>